import { Link } from "gatsby"
import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import ListItem from "./ListItem"

type Props = {}

export default function Footer({}: Props) {
  return (
    <footer className="bg-[#343434]" aria-labelledby="footer-heading">
      <div className="container dnonedesk">
    
    <section className="accordion-wrapper" data-accordion>
     
      <details className="w-[25rem]">
        <summary>
        WHAT WE OFFER
          <svg xmlns="http://www.w3.org/2000/svg" className="icon float-right" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
          </svg>
        </summary>
        <div className="details-info mht">
         <p>Hyperautomation</p>
         <p>Intelligent Document Processing</p>
         <p>Robotic Process Automation</p>
         
        </div>
      </details>
      <details className="w-[25rem]">
        <summary >
        Industry Solutions
          <svg xmlns="http://www.w3.org/2000/svg" className="icon float-right" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
          </svg>
        </summary>
        <div className="details-info mht">
      <p>Hospitality & Real Estate</p>
      <p>Title Insurance & Title Processing</p>
      <p>Mortage & Credit Unions</p>
      <p>Shared Services</p>
      <p>Manufacturing</p>
      <p>Automotive</p>
      <p>Cross Industry</p>
    
        </div>
      </details>
      <details className="w-[25rem]">
        <summary >
        LEARN
          <svg xmlns="http://www.w3.org/2000/svg" className="icon float-right" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
          </svg>
        </summary>
        <div className="details-info mht">
          <p>Webinars</p>
          <p>Blog</p>
          <p>News</p>
          <p>Pocket Resources
</p>
          <p>Media Kit</p>
          <p>ROI Calculator</p>
         
         </div>
      </details>
      <details className="w-[25rem]">
        <summary >
        Platform
          <svg xmlns="http://www.w3.org/2000/svg" className="icon float-right" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd" d="M10 3a1 1 0 011 1v5h5a1 1 0 110 2h-5v5a1 1 0 11-2 0v-5H4a1 1 0 110-2h5V4a1 1 0 011-1z" clip-rule="evenodd" />
          </svg>
        </summary>
        <div className="details-info mht">
          <p>Overview</p>
          <p>RAPFlow</p>
          <p>RAPBot</p>
        
         
         </div>
      </details>
      
    </section>
    
   
  </div>
      <h2 id="footer-heading" className="sr-only dnone">
        Footer
      </h2>
      <div className="px-4 py-12 mx-auto max-w-full sm:px-6 lg:px-16 dnone">
        <div className="grid xl:grid-cols-5  md:grid-cols-5 xl:gap-8">
          <div className="text-white xl:col-span-1">
            <Link
              to="#"
              className="text-lg font-bold grid place-items-center grid-rows-2 tracking-tighter text-blue-600 transition duration-500 ease-in-out transform tracking-relaxed lg:pr-8"
            >
              <img className="row-span-1" src="/images/rap-logo-without-tag.svg" alt="rap logo" />
            </Link>
            {/* <p className="mt-2 text-sm leading-6 text-gray-200/90 font-dm-sans">
              Next-Gen Intelligent Process Automation Platform for Enterprise
              Hyperautomation Solutions
            </p> */}
          </div>
            <div className="md:grid md:grid-cols-1 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold tracking-wider text-white uppercase font-dm-sans">
                  {" "}
                  What We Offer{" "}
                </h3>
                <ul role="list" className="mt-4 space-y-2 text-gray-100">
                  <li>
                    <ListItem title="Hyperautomation" link="#" />
                  </li>
                  <li>
                    <ListItem title="Intelligent Document Processing" link="#" />
                  </li>
                  <li>
                    <ListItem title="Robotic Process Automation" link="#" />
                  </li>
                </ul>
                <h3 className="text-sm font-semibold tracking-wider text-white uppercase font-dm-sans pt-6">
                  {" "}
                  Platform{" "}
                </h3>
                <ul role="list" className="mt-4 space-y-2 text-gray-100">
                  <li>
                    <ListItem title="Overview" link="#" />
                  </li>
                  <li>
                    <ListItem title="RAPFlow" link="#" />
                  </li>
                  <li>
                    <ListItem title="RAPBot" link="#" />
                  </li>
                </ul>
                <h3 className="text-sm font-semibold tracking-wider text-white uppercase font-dm-sans pt-6 pb-2">
                  {" "}
                  Why RAP{" "}
                </h3>
                <h3 className="text-sm font-semibold tracking-wider text-white uppercase font-dm-sans pt-2 pb-2">
                  {" "}
                  Case Studies & Testimonials{" "}
                </h3>
                <h3 className="text-sm font-semibold tracking-wider text-white uppercase font-dm-sans pt-2 pb-2">
                  {" "}
                  Awards & Recognition{" "}
                </h3>
              </div>
            </div>
            <div className="md:grid md:grid-cols-1 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold tracking-wider text-white uppercase font-dm-sans">
                  {" "}
                  Industry Solutions{" "}
                </h3>
                <ul role="list" className="mt-4 space-y-2 text-gray-100">
                  <li>
                    <ListItem title="Hospitality & Real Estate" link="#" />
                  </li>
                  <li>
                    <ListItem title="Mortage & Credit Unions" link="#" />
                  </li>
                  <li>
                    <ListItem title="Title Insurance & Title Processing" link="#" />
                  </li>
                 
                  <li>
                    <ListItem title="Shared Services" link="#" />
                  </li>
                  
                
                  <li>
                    <ListItem title="Manufacturing" link="#" />
                  </li>
                  <li>
                    <ListItem title="Cross Industry" link="#" />
                  </li>
              
                  <li>
                    <ListItem title="Automotive" link="#" />
                  </li>
                </ul>
              </div>
              <div>
              </div>
            </div>
            <div className="md:grid md:grid-cols-1 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold tracking-wider text-white uppercase font-dm-sans">
                  {" "}
                  Role-Based Solutions{" "}
                </h3>
                <ul role="list" className="mt-4 space-y-2 text-gray-100">
                  <li>
                    <ListItem title="CxOs/Business Unit" link="#" />
                  </li>
                  <li>
                  <ListItem title="Finance" link="#" />
                  </li>
                  <li>
                  <ListItem title="Operations" link="#" />
                  </li>
                  <li>
                  <ListItem title="IT" link="#" />
                  </li>
                  <li>
                  <ListItem title="HR" link="#" />
                  </li>
                  <li>
                  <ListItem title="Sales and Marketing" link="#" />
                  </li>
                </ul>
                <h3 className="text-sm font-semibold tracking-wider text-white uppercase font-dm-sans pt-6 pb-2">
                  {" "}
                  Company{" "}
                </h3>
                <h3 className="text-sm font-semibold tracking-wider text-white uppercase font-dm-sans pt-2 pb-2">
                  {" "}
                  Partner with Us{" "}
                </h3>
                <h3 className="text-sm font-semibold tracking-wider text-white uppercase font-dm-sans pt-2 pb-2">
                  {" "}
                  Work with Us{" "}
                </h3>
                <h3 className="text-sm font-semibold tracking-wider text-white uppercase font-dm-sans pt-2 pb-2">
                  {" "}
                  Contact Us{" "}
                </h3>
              </div>
            </div>
            <div className="md:grid md:grid-cols-1 md:gap-8">
              <div>
                <h3 className="text-sm font-semibold tracking-wider text-white uppercase font-dm-sans">
                  {" "}
                  Learn{" "}
                </h3>
                <ul role="list" className="mt-4 space-y-2 text-gray-100">
                  <li>
                    <ListItem title="Webinars" link="#" />
                  </li>
                  <li>
                  <ListItem title="Blog" link="#" />
                  </li>
                  <li>
                    <ListItem title="News" link="#" />
                  </li>
                  <li>
                    <ListItem title="Pocket Resources" link="#" />
                  </li>
                  <li>
                    <ListItem title="Media Kit" link="#" />
                  </li>
                  <li>
                    <ListItem title="ROI Calculator" link="#" />
                  </li>
                </ul>
                <h3 className="text-sm font-semibold tracking-wider text-white uppercase font-dm-sans pt-6">
                  {" "}
                  Location{" "}
                </h3>
                <ul role="list" className="mt-4 space-y-2 text-gray-100">
                  <li>
                    <ListItem title="India, USA" link="#" />
                  </li>
                  </ul>
              </div>
            </div>
          
        </div>
      </div>
    </footer>
  )
}
