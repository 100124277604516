import React from "react"
import { Link } from "gatsby"

type Props = {
  title: string
  link: string
}

export default function ListItem({ title, link }: Props) {
  return (
    <Link
      to={link}
      className="text-base font-normal text-gray-500 transition duration-500 ease-in-out hover:text-white font-dm-sans"
    >
      {title}
    </Link>
  )
}
