/* This example requires Tailwind CSS v2.0+ */
import React, { Fragment } from "react"
import { NavLink, useLocation } from "react-router-dom";
import { Popover, Transition } from "@headlessui/react"
import {
  BookmarkAltIcon,
  CalendarIcon,
  ChartBarIcon,
  CursorClickIcon,
  MenuIcon,
  PhoneIcon,
  PlayIcon,
  RefreshIcon,
  ShieldCheckIcon,
  SupportIcon,
  ViewGridIcon,
  XIcon,
} from "@heroicons/react/outline"
import { ChevronDownIcon } from "@heroicons/react/solid"
import { Link } from "gatsby"

const solutions = [

 
  {
    name: "Integrations",
    description: "Connect with third-party tools .",
    href: "#",
    icon: ViewGridIcon,
  },
  {
    name: "Automations",
    description:
      "Build strategic funnels that will drive",
    href: "#",
    icon: RefreshIcon,
  },
]
const callsToAction = [
  { name: "Watch Demo", href: "#", icon: PlayIcon },
  { name: "Contact Sales", href: "#", icon: PhoneIcon },
  { name: "Contact Sales", href: "#", icon: PhoneIcon },
  { name: "Contact Sales", href: "#", icon: PhoneIcon },
]
const resources = [
  {
    name: "Help Center",
    description:
      "Get all of your questions answered in our forums or contact support.",
    href: "#",
    icon: SupportIcon,
  },
  {
    name: "Guides",
    description:
      "Learn how to maximize our platform to get the most out of it.",
    href: "#",
    icon: BookmarkAltIcon,
  },
  {
    name: "Events",
    description:
      "See what meet-ups and other events we might be planning near you.",
    href: "#",
    icon: CalendarIcon,
  },
  {
    name: "Security",
    description: "Understand how we take your privacy seriously.",
    href: "#",
    icon: ShieldCheckIcon,
  },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

export default function Header() {
  return (

    <div className="bg-white">
      
    
      <div className="hidden sm:block ">
        
        <div className=" mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="flex flex-row justify-end gap-4  head f12">
           
            <img src="/images/linkedin.svg" alt="linkedin" />
            <img src="/images/twitter (2).svg" alt="twitter" />
            <img src="/images/facebook (2).svg" alt="facebook" />
            <div className="dropdown">
            <Link to="#" className="ml-6 text-black font-dm-sans ">
              Company
            </Link>
  <div className="dropdown-content">
  <p>Hello World!</p>
  <p>Hello World!</p>
  <p>Hello World!</p>
  </div>
</div>
            
            <Link to="#" className="text-black font-dm-sans ">
              Partner with us
            </Link>
            <Link to="#" className="text-black font-dm-sans ">
              Work with us
            </Link>
            <Link to="#" className="text-black font-dm-sans ">
              Contact us
            </Link>
          </div>
        </div>
      </div>

      
          <nav className="dnone relative bg-white head  text-gray-900 lg:mt-[1rem]">
    <div className=" containerhead mx-auto flex justify-between">
      <div className="relative block text-xl text-blue-600 font-bold ml100">  
      <img
                  src="/images/rap-logo.svg"
                  alt="Rapid Acceleration Partners"
                  className=""
                /></div>
    
          
          <ul className="flex  ml-[-12rem] head mlh">
      
       
       
            
        
      <li className="hoverable hover:bg-white-800 hover:text-red">
           <a href="#" className="fonts relative block py-6 px-4 lg:p-6 text-md lg:text-base font-bold bg-black-800 text-black headerclra ">Solution &nbsp; <i className="fa fa-angle-down text-[18px]" ></i></a>
           <div className="p-6 mega-menu mb-16 sm:mb-0 shadow-xl bgwt z-10 ">
             <div className="containerhead mx-auto w-full flex flex-wrap justify-between mx-2">
              
               <ul className="px-4 w-full  lg:w-1/6  pb-6 pt-6 lg:pt-3">
                 <div className="flex items-center">
                  
                   <h3 className="menutitle mb-2 ">What We Offer </h3>
                 </div>
                 <p className="text-black  text-md">Hyperautomation</p>
                 <p className="text-black  text-md">Intelligent Document Processing</p>
                 <p className="text-black  text-md">Robotic Process Automation</p>
             
                 <div className="flex items-center py-3">
                  
                   
                 </div>
               </ul>
               <ul className="px-4 w-full  lg:w-1/4  pb-6 pt-6 lg:pt-3">
                 <div className="flex items-center">
                  
                   <h3 className="menutitle mb-2">Industry Solution</h3>
                 </div>
                 <p className=" text-black text-md">Hospitality & Real Estate </p>
                 <p className=" text-black  text-md">Mortgage & Credit Unions </p>
                 <p className=" text-black  text-md">Title Insurance & Title Processing </p>
                 
                 <p className=" text-black  text-md">Shared Service </p>
              
                 <p className=" text-black  text-md">Manufacturing </p>
                 
                 <p className="text-black  text-md">Automotive </p>
                 <p className="text-black  text-md">Cross Industry </p>
                
               </ul>
               <ul className="px-4 w-full  lg:w-1/6 pb-6 pt-6 lg:pt-3">
                 <div className="flex items-center">
                  
                   <h3 className="menutitle mb-2">Role Based Solution</h3>
                 </div>
                 
                 <p className=" text-black  text-md">Finance </p>
                 <p className=" text-black  text-md">Operations </p>
                 <p className=" text-black text-md">IT </p>
                 <p className="  text-black text-md">HR </p>
                 <p className="  text-black text-md">Supply and Chain </p>
                 <p className="  text-black text-md">Sales & Marketing </p>
                 
               </ul>
               <ul className=" w-full  lg:w-1/6  pb-6 pt-6 lg:pt-3 ">
               <div className="flex items-center">
                 <h3 className="menutitle mb-5">Hospitality</h3><br/>
                 </div>
                 <img  className="menuimg mb-2" src="/images/tech.png" alt="facebook" /><br/>
                 <p className="text-md  text-black  ">Scale your hospitality backoffice processes using Hyperautomation.</p>
                 
               </ul>
                 <ul className=" w-full  lg:w-1/6  pb-6 pt-6 lg:pt-3">
                 <div className="flex items-center">
                   
                   <h3 className="menutitle mb-5">Mortgage Processing</h3>
                 </div>
                 <img className=" text-md text-black menuimg" src="/images/tech.png" alt="facebook" /><br/>
                 <p className=" text-md text-black ">Remove inefficiencies across your mortgage processing cycle from origination till closing.</p>
                 
               </ul>
             </div>
           </div>
         </li>
          
         <li className="hoverable hover:bg-white-800 hover:text-black">
           <a href="#" className="fonts relative block py-6 px-4 lg:p-6 text-md lg:text-base font-bold bg-black-800 text-black ">Platform &nbsp; <i className="fa fa-angle-down text-[18px]" ></i></a>
           <div className="p-6 mega-menu mb-16 sm:mb-0 shadow-xl bgwt z-10">
             <div className="container mx-auto w-full flex flex-wrap justify-between mx-2">
              
               <ul className="px-4 w-full sm:w-1/2 lg:w-1/4 pb-6 pt-6 lg:pt-3">
                 <div className="flex items-center">
                  
                   <h3 className="menutitle mb-2">Hyperautomation Platform</h3>
                 </div>
                 <p className=" text-black  text-md">One platform For end-to-end Automation of processes help to save the valuable time of employee.</p>
                 <div className="flex items-center py-3">
                   <svg className="h-6 pr-3 fill-current text-blue-300"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                     <path d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                   </svg>
                   <a href="#" className="text-black bold border-b-2 border-blue-300 hover:text-blue-300">Explore Platform</a>
                 </div>
               </ul>
               <ul className="px-4 w-full sm:w-1/2 lg:w-1/4 pb-6 pt-6 lg:pt-3 mlf100">
                 <div className="flex items-left">
               
                   <h3 className="menutitle mb-2">Bringing Best Of Both World</h3>
                 </div>
                 
                 <div className="flex items-center py-3">
                   <svg className="h-6 pr-3 fill-current text-blue-300"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                     <path d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                   </svg>
                   <a href="#" className="text-black bold border-b-2 border-blue-300 hover:text-blue-300"><b>RAP FLOW</b><br/>AI Orchestration Platform</a>
                 </div>
                 <div className="flex items-center py-3">
                   <svg className="h-6 pr-3 fill-current text-blue-300"
                     xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                     <path d="M20 10a10 10 0 1 1-20 0 10 10 0 0 1 20 0zm-2 0a8 8 0 1 0-16 0 8 8 0 0 0 16 0zm-8 2H5V8h5V5l5 5-5 5v-3z"/>
                   </svg>
                   <a href="#" className="text-black bold border-b-2 border-blue-300 hover:text-blue-300"><b>RAP BOT</b><br/> Intelligent RPA Platoform<br/> </a>
                 </div>
               </ul>
               <ul className="px-4 w-full sm:w-1/2 lg:w-1/3   pb-6 pt-6 lg:pt-3">
                 <div className="flex ">

                   <h3 className=" menutitle ">Integrate With Your Existing Systems</h3>
                 </div>
             
                 <div className="flex items-center py-3 ">
                 <div className="grid grid-cols-3   md:grid-cols-3 lg:grid-cols-3  ">
         <div className="intimg text-lg  p-1 rounded-lg">
         <img className="" src="/images/tech.png" alt="facebook" />
       </div>
       <div className=" intimg  p-1 rounded-lg">
         <img src="/images/tech.png" alt="facebook" />
       </div>
       <div className=" intimg   p-1 rounded-lg">
         <img src="/images/tech.png" alt="facebook" />
       </div>
       <div className=" intimg   p-1 rounded-lg">
         <img src="/images/tech.png" alt="facebook" />
       </div>
       <div className="intimg  p-1 rounded-lg">
         <img src="/images/tech.png" alt="facebook" />
       </div>
       <div className="intimg  p-1 rounded-lg">
         <img src="/images/tech.png" alt="facebook" />
       </div>
       <div className="intimg text-lg  p-1 rounded-lg">
         <img src="/images/tech.png" alt="facebook" />
       </div>
       <div className="intimg text-lg  p-1 rounded-lg">
         <img src="/images/tech.png" alt="facebook" />
       </div>
     
       </div>
      
                 
                 </div>
                 <p className="text-black ">What ever system you use - we got you covered.</p>
               </ul>
           
             </div>
           </div>
         </li>
          
         <li className="hoverable  hover:text-black">
           <a href="#" className="fonts relative block py-6 px-4 lg:p-6 text-md lg:text-base font-bold  text-black ">Why Rap &nbsp; <i className="fa fa-angle-down text-[18px]" ></i></a>
           <div className="p-6 mega-menu mb-16 sm:mb-0 shadow-xl bgwt z-10">
             <div className="container mx-auto w-full flex flex-wrap justify-between mx-2">
             
               <ul className="px-4 w-full sm:w-1/2 lg:w-1/4  pb-6 pt-6 lg:pt-3">
                 <div className="flex">
                  
                   <h3 className="menutitle mb-2">Delivering Peak Efficiencies</h3>
                 </div>
                 <p className=" text-md text-black">Drive the experiences your customers and employees expect, scale your enterprise at will.</p>
                 
               </ul>
               <ul className="px-4 w-full sm:w-1/2 lg:w-1/5  pb-6 pt-6 lg:pt-3">
                 <div className="flex items-center">
                  
                   <h3 className="menutitle  mb-2">Success Stories</h3>
         </div>
                 <p className=" text-md text-black">Case Studies & Testimonials</p>
                 <p className=" text-md text-black">Awards & Recognition</p>
                
               </ul>
               <ul className="px-4 w-full sm:w-1/2 lg:w-1/5   pb-6 pt-6 lg:pt-3">
                 <div className="flex items-center">
                  
                   <h3 className="menutitle mb-2 ">Customer Spotlight</h3>
                 </div>
                 <p className="text-md text-black">Increased conversions through fast quote response</p>
                 <p className="text-md text-black">A major US-based auto dealer improved conversions by reducing customer quote response with intelligent automation.</p>
                 <a href="#" className="text-black bold border-b-2 border-blue-300 hover:text-blue-300">Find out more...</a>
               </ul>
             

               <ul className="px-4 w-full sm:w-1/2 lg:w-1/5  mlf25 ">
                
               </ul>
               <ul className="px-4 w-full sm:w-1/2 lg:w-1/5  mlf25 ">
                
               </ul>
             </div>
           </div>
         </li>
         
         
        
         <li className="hoverable hover:bg-white-800 hover:text-black">
           <a href="#" className="fonts relative block py-6 px-4 lg:p-6 text-md lg:text-base font-bold bg-black-800 text-black ">Resource &nbsp; <i className="fa fa-angle-down text-[18px]" ></i></a>
           <div className="p-6 mega-menu mb-16 sm:mb-0 shadow-xl bgwt z-10">
             <div className="container mx-auto w-full flex flex-wrap justify-between mx-2">
             
               <ul className="px-4 w-full sm:w-1/2 lg:w-1/6  pb-6 pt-6 lg:pt-3">
                 <div className="flex items-center">
                 
                   <h3 className="menutitle  mb-2">Learn</h3>
                 </div>
                 
                 <p className="blogf">Webinars</p>
                 <p className="blogf">Blog</p>
                 <p className="blogf">News</p>
                 <p className="blogf">Pocket Resources</p>
                 <p className="blogf">Media Kit</p>
                 <p className="blogf">ROI Calculator</p>
                
               </ul>
               <ul className="px-4 w-full sm:w-1/2 lg:w-1/2  pb-6 pt-6 lg:pt-3">
                 <div className="flex ">
                  
                  
                   <a href="#" className="text-black bold border-b-2 border-black-300 hover:text-black-300">Find</a>
                 </div>
             
                 <div className="flex py-5">
                 <div className="card rapcard">
<img className="rapcardimg" src="/images/tech.png" alt="Avatar" />
<div className=" p-[1rem] mpb">
 <h4><b>John Doe</b></h4> 
 <p>Create frictionless process pipelines with no manual and repetitive tasks.</p> 
</div>
</div>&emsp;
<div className="card rapcard">
<img className="rapcardimg" src="/images/tech.png" alt="Avatar" />
<div className="p-[1rem] mpb">
 <h4><b>John Doe</b></h4> 
 <p>Create frictionless process pipelines with no manual and repetitive tasks.</p> 
</div>
</div>&emsp;
<div className="card rapcard">
<img className="rapcardimg" src="/images/tech.png" alt="Avatar" />
<div className="p-[1rem] mpb">
 <h4 className=""><b>John Doe</b></h4> 
 <p>Create frictionless process pipelines with no manual and repetitive tasks.</p> 
</div>
</div>

                 
                 </div>
               </ul>
               <ul className="px-4 w-full sm:w-1/2 lg:w-1/3  pb-6 pt-6 lg:pt-3">
                 <div className="flex items-center">
                   
                   <h3 className="menutitle mb-2">Top Picks</h3>
                 </div>
                 <p className=" text-md">How to pick use cases ebook.</p>
                 <p className=" text-md">ROI Calculator</p>
                 
               </ul>

             </div>
           </div>
         </li>
         
     
     
       
       
   
          </ul>
         
     
       
    
          <a
       href="#"
       className=" mr-[8rem]    mob px-5 py-2  hbtntext mt-2 text-base font-medium text-white hbtn border border-transparent rounded-md shadow-sm whitespace-nowrap hover:bg-gray-900"
     >
       Book a demo
     </a>
   

        </div>
      </nav>

      
      <div className="nav">
    <nav className="bg-white dnonedesk">
        <a href="javascript:void(0);" className="mobile-menu-trigger"><i className="fa fa-bars" aria-hidden="true"></i></a>
        <ul className="menu menu-bar bga">
            <li>
                <a href="javascript:void(0);" className="menu-link menu-bar-link" aria-haspopup="true">Solution
                    </a>
                <ul className="mega-menu mega-menu--multiLevel">
                    <li>
                        <a href="javascript:void(0);" className="menu-link mega-menu-link" aria-haspopup="true">What We Offer
                            </a>
                        <ul className="menu menu-list">
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Hyperautomation</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Intelligent Document Processing</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Robotic Process Automation</a>
                            </li>
                          
                          
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:void(0);" className="menu-link mega-menu-link" aria-haspopup="true">Industry Solution
                            </a>
                        <ul className="menu menu-list">
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Hospitality & Real Estate</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Mortgage & Credit Unions</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Title Insurance & Title Processing </a>
                            </li>
                            {/* <li>
                                <a href="/page" className="menu-link menu-list-link">Accounting & Finance Institution </a>
                            </li> */}
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Shared Service</a>
                            </li>
                            {/* <li>
                                <a href="/page" className="menu-link menu-list-link">Logistics</a>
                            </li> */}
                            {/* <li>
                                <a href="/page" className="menu-link menu-list-link">Healthcare</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Manufacturing</a>
                            </li> */}
                            {/* <li>
                                <a href="/page" className="menu-link menu-list-link">eCommerce</a>
                            </li> */}
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Automotive</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Cross Industry</a>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:void(0);" className="menu-link mega-menu-link" aria-haspopup="true">Role Based Solution
                            </a>

                        <ul className="menu menu-list">
                             {/* <li>
                                <a href="/page" className="menu-link menu-list-link">CxOs/Business Unit</a>
                            </li> */}
                            <li> 
                                <a href="/page" className="menu-link menu-list-link">Finance</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Operations</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">IT</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">HR</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Supply Chain</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Sales & Marketing</a>
                            </li>

                        </ul>
                    </li>
                  
                    <li className="mobile-menu-back-item">
                        <a href="javascript:void(0);" className="menu-link mobile-menu-back-link">Back</a>
                    </li>
                </ul>
            </li>

          
           
            <li>
                <a href="javascript:void(0);" className="menu-link menu-bar-link" aria-haspopup="true">Platform
                    </a>
                <ul className="mega-menu mega-menu--multiLevel">
                    <li>
                        <a href="javascript:void(0);" className="menu-link mega-menu-link" aria-haspopup="true">Hyper Automation Platform
                            </a>
                        <ul className="menu menu-list">
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Explore Platform</a>
                            </li>
                            
                          
                      
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:void(0);" className="menu-link mega-menu-link" aria-haspopup="true"> Bringing Best Of Both World
                            </a>
                        <ul className="menu menu-list">
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Rap Flow</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Rap Bot</a>
                            </li>
                          
                      
                        </ul>
                    </li>
                   
                  
                    <li className="mobile-menu-back-item">
                        <a href="javascript:void(0);" className="menu-link mobile-menu-back-link">Back</a>
                    </li>
                </ul>
            </li>
            <li>
                <a href="javascript:void(0);" className="menu-link menu-bar-link" aria-haspopup="true">Why Rap
                    </a>
                <ul className="mega-menu mega-menu--multiLevel">
                    <li>
                        <a href="javascript:void(0);" className="menu-link mega-menu-link" aria-haspopup="true">Success Stories
                            </a>
                        <ul className="menu menu-list">
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Case Studies & Testimonial</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Awards & Recognition</a>
                            </li>
                          
                          
                    
                        </ul>
                    </li>
                  
                 
                  
                    <li className="mobile-menu-back-item">
                        <a href="javascript:void(0);" className="menu-link mobile-menu-back-link">Back</a>
                    </li>
                </ul>
            </li>
    
            <li>
                <a href="javascript:void(0);" className="menu-link menu-bar-link" aria-haspopup="true">Resourse 
                    </a>
                <ul className="mega-menu mega-menu--multiLevel">
                    <li>
                        <a href="javascript:void(0);" className="menu-link mega-menu-link" aria-haspopup="true">Learn
                            </a>
                        <ul className="menu menu-list">
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Webinars</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Blog</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">News</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Pocket Resources</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">Media Kit</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">ROI Calculator</a>
                            </li>
                          
                  
                        </ul>
                    </li>
                    <li>
                        <a href="javascript:void(0);" className="menu-link mega-menu-link" aria-haspopup="true">Top Picks
                            </a>
                        <ul className="menu menu-list">
                            <li>
                                <a href="/page" className="menu-link menu-list-link">How to pick use cases ebook.</a>
                            </li>
                            <li>
                                <a href="/page" className="menu-link menu-list-link">ROI Calculator</a>
                            </li>
                            
                          
                        </ul>
                    </li>
                   
                  
                    <li className="mobile-menu-back-item">
                        <a href="javascript:void(0);" className="menu-link mobile-menu-back-link">Back</a>
                    </li>
                </ul>
            </li>
          

       
        </ul>
    </nav>


    
</div>

    

    </div>
  )
}
